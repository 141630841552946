var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "popup", staticClass: "popup_dim" }, [
      _c(
        "div",
        {
          staticClass: "popup_wrap",
          staticStyle: { width: "800px", height: "520px" },
        },
        [
          _c(
            "button",
            {
              staticClass: "layer_close",
              on: {
                click: function ($event) {
                  return _vm.closePopup()
                },
              },
            },
            [_vm._v("close")]
          ),
          _c("div", { staticClass: "popup_cont" }, [
            _c("h1", { staticClass: "page_title" }, [
              _vm._v(" " + _vm._s(_vm.$t("common.FILE_UPLOAD.001"))),
            ]),
            _c(
              "div",
              { staticClass: "content_box" },
              [
                _c("DEXT5Upload", {
                  ref: "dextupload",
                  attrs: {
                    id: _vm.componentId,
                    single: _vm.single,
                    category: _vm.category,
                    width: "100%",
                    height: "200px",
                    "file-info": _vm.value,
                    "category-info": _vm.catList,
                  },
                  on: { completed: _vm.uploadCompleted },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                on: {
                  click: function ($event) {
                    return _vm.closePopup()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.022")))]
            ),
          ]),
        ]
      ),
    ]),
    _c(
      "a",
      {
        class: _vm.$ekmtcCommon.isEmpty(_vm.uploadList)
          ? "button sm gray file"
          : "button sm blue",
        attrs: { href: "#" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.openPopup.apply(null, arguments)
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("comp.300")))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }