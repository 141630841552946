<template>
  <div>
    <!-- container_ekmtc -->
    <div class="popup_dim" ref="popup">
      <div class="popup_wrap" style="width:800px; height:520px;">
        <button class="layer_close" @click="closePopup()">close</button>
        <div class="popup_cont">
          <h1 class="page_title"> {{ $t('common.FILE_UPLOAD.001') }}</h1>
          <div class="content_box">
            <DEXT5Upload
              ref="dextupload"
              :id="componentId"
              :single="single"
              :category="category"
              width="100%"
              height="200px"
              @completed="uploadCompleted"
              :file-info="value"
              :category-info="catList"
            />
          </div>
        </div>
        <div class="mt10 text_center">
          <!-- <a class="button blue lg" @click="fileSave()">저장</a>&nbsp;&nbsp; -->
          <a class="button blue lg" @click="closePopup()">{{ $t('msg.MYIN030T010.022') }}</a>
        </div>
      </div>
    </div>

    <a :class="$ekmtcCommon.isEmpty(uploadList) ? 'button sm gray file' : 'button sm blue'" href="#" @click.prevent="openPopup">{{ $t('comp.300') }}</a><!--button class="button blue lg mt10 layer_open" @click="openPopup(); return false">팝업1</button-->
  </div>
</template>

<script>
import dangerShipList from '@/api/rest/trans/dangerShipList'
export default {
  components: {
    // DEXT5Upload: () => import('@/components/DEXT5Upload')
    DEXT5Upload: () => import('@/components/common/DEXT5UploadForMr')

  },
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    single: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    catList: {
      type: Array,
      default: function () {
        return []
      }
    },
    row: {
      type: Number,
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      data: {
        as_req_rno: '',
        file_info: [],
        req_rno: ''
      },
      uploadList: []
    }
  },
  computed: {
    componentId () {
      if (typeof window.upload_component_cnt === 'undefined') {
        window.upload_component_cnt = 0
      }
      window.upload_component_cnt++
      const c = window.upload_component_cnt

      return `upload_com_id_${c}`
    },
    category () {
      return this.catList.length !== 0
    }
  },
  mounted () {
  },
  methods: {
    uploadCompleted (fileInfo) {
      //this.$emit('completed', fileInfo)
      this.uploadList = fileInfo
    },
    openPopup () {
      const st = $('html').scrollTop()
      $(this.$refs.popup).data('scroll-top', st).fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    closePopup () {
      let file = this.$refs.dextupload.getFileInfo()

      // 파일 첨부 됬으면 '닫기' 눌렀을때 파일저장
      if (file.length > 0) {
        this.fileSave()
      }
      this.$refs.dextupload.ResetDext()

      const st = $(this.$refs.popup).data('scroll-top')
      $(this.$refs.popup).fadeOut()
      $('body,html').removeAttr('style')
      $('html').scrollTop(st)
      return false
    },
    fileCallback () {
      this.$emit('rtnReqRno', this.req_rno, this.row)
      this.$emit('close')
    },
    fileSave: function () {
      this.$emit('completed', { fileInfo: this.uploadList, componentId: this.componentId })
      // this.data.file_info = this.value
      // this.data.file_info.forEach((itm, idx) => {
      //   this.data.file_info[idx].fileSt = this.data.file_info[idx].new
      // })
      // dangerShipList.fileSave(this.data).then(response => {
      //   console.log('response.data=' + JSON.stringify(response.data))
      //   this.req_rno = response.data
      //   // this.alert('등록되었습니다.')
      //   this.$ekmtcCommon.alertCallback(this.$t('msg.SPEC010G010.011'), this.fileCallback)
      // }).catch(err => {
      //   console.log(err)
      // })
    }
  }
}
</script>
<style>
.DEXT_fiVe_UP_ly_wrapper{
  z-index: 99999999 !important;
}
</style>
